import React from 'react';
import { useInView } from 'react-intersection-observer';

const LazyImage = ({ src, alt, className, ...props }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Load the image only once when it comes into view
    threshold: 0.2, // Trigger when 20% of the image is visible
  });

  return (
    <img
      ref={ref}
      src={inView ? src : ''} // Load image only when it's in view
      alt={alt}
      className={className}
      loading="lazy" // Use browser's native lazy loading as well
      {...props}
    />
  );
};

export default LazyImage;
