import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './ProjectDetails.css';
import { useNavigate } from 'react-router-dom';
import LazyImage from '../../LazyImage';
// Import des images et vidéos

import ENCOUNTERS_1 from '../../assets/portfoliomain/details/encounters/Encounters_image1.png';
import ENCOUNTERS_2 from '../../assets/portfoliomain/details/encounters/Encounters_image2.png';
import ENCOUNTERS_3 from '../../assets/portfoliomain/details/encounters/Encounters_image3.png';

import BIGLITTLEFEUD_1 from '../../assets/portfoliomain/details/encounters/biglittlefeud_image1.png';
import BIGLITTLEFEUD_2 from '../../assets/portfoliomain/details/encounters/biglittlefeud_image2.png';
import BIGLITTLEFEUD_3 from '../../assets/portfoliomain/details/encounters/biglittlefeud_image3.png';
import BIGLITTLEFEUD_4 from '../../assets/portfoliomain/details/encounters/biglittlefeud_image4.png';

import LAMU_1 from '../../assets/portfoliomain/details/lamu/lamu_1.jpg';
import LAMU_2 from '../../assets/portfoliomain/details/lamu/lamu_2.jpg';
import LAMU_3 from '../../assets/portfoliomain/details/lamu/lamu_3.png';
import LAMU_4 from '../../assets/portfoliomain/details/lamu/lamu_4.png';

import LAFIEVRE_1 from '../../assets/portfoliomain/details/lafievre/lafievre_image1.png';
import LAFIEVRE_2 from '../../assets/portfoliomain/details/lafievre/lafievre_image2.png';
import LAFIEVRE_4 from '../../assets/portfoliomain/details/lafievre/lafievre_image4.png';

import OUTRUNNER_1 from '../../assets/portfoliomain/details/outrunner/outrunner_image1.jpg';
import OUTRUNNER_2 from '../../assets/portfoliomain/details/outrunner/outrunner_image2.png';
import OUTRUNNER_3 from '../../assets/portfoliomain/details/outrunner/outrunner_image3.png';


import BEETLES_1 from '../../assets/portfoliomain/details/beetles/beetles_image1.png';
import BEETLES_2 from '../../assets/portfoliomain/details/beetles/beetles_image2.png';
import BEETLES_3 from '../../assets/portfoliomain/details/beetles/beetles_image3.png';


import HUMANOPHOBIA_1 from '../../assets/portfoliomain/details/humanophobia/humanophobia_image1.png';
import HUMANOPHOBIA_2 from '../../assets/portfoliomain/details/humanophobia/humanophobia_image2.png';
import HUMANOPHOBIA_3  from '../../assets/portfoliomain/details/humanophobia/humanophobia_image3.png';


import SUGARRUSH_1 from '../../assets/portfoliomain/details/sugarrush/sugarrush_image1.png';
import SUGARRUSH_2 from '../../assets/portfoliomain/details/sugarrush/sugarrush_image2.png';
import SUGARRUSH_3 from '../../assets/portfoliomain/details/sugarrush/sugarrush_image3.png';
import SUGARRUSH_4 from '../../assets/portfoliomain/details/sugarrush/sugarrush_image4.png';


import ERREUR_1 from '../../assets/portfoliomain/details/erreur/erreur_image1.png';
import ERREUR_2 from '../../assets/portfoliomain/details/erreur/erreur_image2.png';

import MOB from '../../assets/portfoliomain/mob-s3-port.webp';
import FRONTIERE from '../../assets/portfoliomain/frontiere_bg-port.png';
import DEVILMAIN from '../../assets/portfoliomain/devil-port.webp';
import HELLUVA from '../../assets/portfoliomain/helluva-port.webp';
import GENSHIN from '../../assets/portfoliomain/genshin_music-port.png';
import LUCIFARIN from '../../assets/portfoliomain/lucifarin_thumbnail.jpg';

import PHOTO_1_PLATINUM from '../../assets/portfoliomain/platinum_1.png';
import PHOTO_2_PLATINUM from '../../assets/portfoliomain/platinum_2.png';
import PHOTO_3_PLATINUM from '../../assets/portfoliomain/platinum_3.png';
import VIDEO_PLATINUM from '../../assets/portfoliomain/platinum_video.mp4';


import PHOTO_1_CODA from '../../assets/portfoliomain/details/ITW_CODA.png';
import PHOTO_2_CODA from '../../assets/portfoliomain/details/LIVEJAZZ_CODA.png';
import PHOTO_3_CODA from '../../assets/portfoliomain/details/LIVEREGGAE_CODA.png';

// Tableau des projets
const portfolioItems = [
  { id: 'platinum-end',image: VIDEO_PLATINUM, category: 'Doublage', title: 'Platinum End', description: "Platinum End est une série d'animation japonaise. J'ai travaillé sur le doublage de la version française, notamment en tant qu'opérateur son sur les enregistrements et en tant que mixeur sur les derniers épisodes de la série", additionalImages: [PHOTO_1_PLATINUM,PHOTO_2_PLATINUM,PHOTO_3_PLATINUM] },
  { id: 'jujutsu-kaisen',youtubeUrls: [
    'https://www.youtube.com/embed/gE8VU-q4law', //Savage Song
    'https://www.youtube.com/embed/Lk8t6WR8_h0', //DreamMaker
    'https://www.youtube.com/embed/vNxlJP_EsEg', //Jujutsu
    'https://www.youtube.com/embed/w0AzriPycLE', //Mouvement de la terre
    'https://www.youtube.com/embed/0K7VCW0lvCE', //Amants sacrifiés
    'https://www.youtube.com/embed/8rfdXTYwROs', //Slice of Life
    
    'https://www.youtube.com/embed/obKUHUpr_To', //Coco
    
    'https://www.youtube.com/embed/nz4Qc6fIaOQ', //Forgeuse d'os
    
    'https://www.youtube.com/embed/qmguZMfMG-w',  //Galant
    'https://www.youtube.com/embed/4PXKjOgiTwc', //One Last Stop
    'https://www.youtube.com/embed/kLpC6-uSOPM', //Danganronpa
    
    
  ], title: 'Publicités',whatidid: (
    <span>
      J'ai travaillé sur plus d'une vingtaine de spots publicitaires en français et en anglais en tant qu'opérateur son et mixeur son.
    </span>) },

  
  { id: 'coda', youtubeUrls:[ 'https://www.youtube.com/embed/SPo3tCna5qU'], image: PHOTO_1_CODA, additionalImages: [PHOTO_2_CODA,PHOTO_3_CODA], title: 'CODA',
    description: (
    <span>
      CODA est mon <b>projet de fin de 2eme année de BTS</b>. Ce magazine musical est centré sur le <b>trio de jazz</b> Frederic Viale Trio ainsi que sur le <b>groupe de Reggae</b> Nartubians. L'émission alterne <b>portraits et captations live</b>.
      </span>),
    whatidid: (
    <span>
      Pour ce magazine, il fallait créer un <b>générique</b> et des <b>jingles</b> qui collaient à la <b>direction artistique</b> de l’émission.
      J’ai enregistré des <b>interviews</b> et effectué des <b>captations de concert</b> en gérant les <b>retours des musiciens</b>.
      J’ai réalisé la <b>post-production</b> son de l’émission (<b>montage-son, mixage</b>).
    </span>
  )},
  { id: 'la-fievre', youtubeUrls:['https://www.youtube.com/embed/tzdKk4NS158'], image: LAFIEVRE_1, additionalImages: [LAFIEVRE_2,LAFIEVRE_4], title: 'La Fièvre', description: (
    <span>
      La Fièvre est un <b>court-métrage de fiction</b>. Centré sur la <b>danse</b>, on accompagne une <b>rupture compliqué</b> entre deux femmes.
      </span>),
    whatidid: (
    <span>
      Pour ce court métrage de fiction, j’ai réalisé la postproduction son en appliquant les connaissances de montage son et de mixage cinéma qui m’ont été transmises à l’INA par des professionnels.
    </span>
  )},
  //{ id: 'this-savage-song', youtubeUrls:['https://www.youtube.com/embed/gE8VU-q4law'], image: SAVAGE, category: 'Publicité', title: 'This Savage Song', description: 'Description spécifique pour This Savage Song.' },
  { id: 'mob-psycho-100-s03', image: MOB, title: 'MOB PSYCHO 100 S03', whatidid: (
    <span>
      J'ai réalisé les enregistrements et mixages de la saison 3 de la série d'animation japonaise Mob Psycho 100. J'ai également travaillé sur de nombreux autres projets de doublage d'animation. <i>Liste non exhaustive : </i><br />86, L'Attaque des Titans, My Hero Academia, Vinland Saga, Edens Zero, Rent-a-Girlfriend, Jujutsu Kaisen, Metallic Rouge, Call of the Night, My Dress up Darling, Nagatoro, Blue Exorcist, Black Clover, The Eminence in Shadow, Platinum End, Orient, The Devil is a Part-Timer, Magus Bride, To Your Eternity, The Dawn of the Witch, I'm Standing on a Million Lives
    </span>) },
  { id: 'encounters', youtubeUrls:['https://www.youtube.com/embed/mA66Gmdh9Vg'],image: ENCOUNTERS_1,  additionalImages: [ENCOUNTERS_2, ENCOUNTERS_3],title: 'Encounters', 
    description: (
      <span>
        Encounters est une <b>série live</b> de 6 épisodes, sortie en 2022 et disponible sur Prime Video.
        </span>),     
    whatidid: (
    <span>
      J'ai effectué les <b>enregistrements</b> et le <b>mixage</b> du doublage de la version française de cette série.
    </span>
  ) },
  { id: 'big-little-feud', image: BIGLITTLEFEUD_1,  additionalImages: [BIGLITTLEFEUD_2,BIGLITTLEFEUD_3,BIGLITTLEFEUD_4], title: 'Big Little Feud',
    description: (
      <span>
        <b>Big Little Feud</b> est le <b>premier jeu 3D</b> sur lequel j’ai travaillé. Il a été réalisé
        pour la <b>Game Off 2023</b> en <b>30 jours</b>. 
        </span>),  
    whatidid: (
    <span>
      En plus de <b>l’intégralité de la conception sonore,</b> j’ai
travaillé sur le code (système de loot aléatoire, barre de stamina…), le <b>game design</b> et la <b>narration</b>.
    </span>
  )},
  { id: 'la-frontiere-du-monde', image: FRONTIERE, title: 'La Frontière Du Monde', 
    whatidid: (
    <span>
      Pour mon <b>projet de fin de licence</b> à l’INA, j’ai réalisé une <b>fiction
sonore binaurale</b>. J’ai écris le <b>scénario</b>, choisi des <b>comédiens</b> puis
réalisé le <b>sound design</b>, les <b>musiques</b> et le <b>mixage</b>. Ce projet de
huit minutes m’a permis de pousser ma créativité dans
l’enregistrement de sons et leur traitement tout en apprenant à
utiliser un workflow différent avec le <b>Dolby Atmos Renderer</b>.

    </span>
  )},

  { id: 'ventorys-inn', youtubeUrls:['https://www.youtube.com/embed/g4A8plazLRw','https://www.youtube.com/embed/qBKw9lWxzd4'], title: 'Ventorys Inn', description: (
    <span>
      <a href="https://teist.itch.io/ventorysinn" target="_blank" rel="noopener noreferrer"><b>Ventory’s Inn</b></a>  est un jeu vidéo réalisé en <b>72 heures</b> dans le cadre de la Ludum Dare 54 dont le thème était <b>"Espace limité"</b>. Il a été réalisé avec <b>3 autres personnes</b> rencontrées numériquement pour l'occasion.
      </span>),
    whatidid: (
    <span>
      Je me suis occupé du son du projet c’est-à-dire de la <b>création de musiques</b>, de voix, du <b>sound
design</b> ainsi que de <b>l’intégration</b> dans le moteur de jeu Unity. Depuis, nous développons activement le jeu et j'ai repris le rôle de <b>game designer</b>.

    </span>
  )},

  { id: 'the-devil-is-a-part-timer-s02', image: DEVILMAIN, category: 'Doublage', title: 'The Devil is A Part Timer! S02', description: 'Description spécifique pour The Devil is A Part Timer! S02.' },
  { id: 'helluva-boss-vf', youtubeUrls: ['https://www.youtube.com/embed/XxYX9RXt9NA'], image: HELLUVA, title: 'Helluva Boss VF', whatidid: (
    <span>
      Helluva Boss est une série d'animation américaine. N'ayant pas la possibilité d'avoir le matériel d'origine, un re-sound design a été effectué. J'ai effectué le mixage du sound design et des voix sur le premier épisode de la série.
    </span>
  ) },
  { id: 'urusei-yatsura', image: LAMU_2, additionalImages: [LAMU_4, LAMU_1,LAMU_3],title: 'Urusei Yatsura',
    whatidid: (
    <span>
      J’ai effectué les enregistrements et les mixage du doublage d'Urusei Yatsura, un remaster d’un série d'animation japonaise humoristique des années 80.
Cette série présente énormément de personnages et joue beaucoup
avec des effets sonores en tout genre. Cela m’a permis de découvrir des
plugs-ins et surtout de me creuser la tête pour réaliser des effets proches
de la version originale avec ce que j’avais à disposition.


    </span>
  )},
  { id: 'lucifarin-live', image: LUCIFARIN, youtubeUrls: ['https://www.youtube.com/embed/k3YIP7Y5_NM'], category : 'Musique', title:'Lucifarin,LiveSession', description: 'Lucifarin est un groupe parisien de rock français',whatidid: (
    <span>
      Claviériste dans un groupe de rock français pendant trois ans, nous avons écrit une dizaine de compositions originales. Nous avons réalisé des démos ainsi que des captations live.

    </span>
  )},

  { id: 'sugar-rush', image: SUGARRUSH_1, additionalImages: [SUGARRUSH_2,SUGARRUSH_3,SUGARRUSH_4],title:'Sugar Rush',
    description: (
      <span>
        <a href="https://camille-misbach.itch.io/sugar-rush" target="_blank" rel="noopener noreferrer"><b>Sugar Rush</b></a> est un jeu vidéo réalisé dans le cadre de l'ENJAM 2024, une game jam de 48 heures avec pour thème 'Trick or Treat'.
        </span>),
        whatidid: (
    <span>
      J'ai travaillé sur la conception du projet ainsi que sur le sound design, la musique et leur intégration dans le moteur de jeu Unity.

    </span>
  )},

  { id: 'outrunner', image: OUTRUNNER_1, additionalImages: [OUTRUNNER_2,OUTRUNNER_3], title:'The Outrunner',
    description: (
      <span>
        The Outrunner est une <b>installation vidéoludique</b> réalisée en <b>3 jours</b> dans le cadre d'<b>ENIAROF</b>, une fête foraine réunissant des <b>installations interactives</b>.<br /><br /> Installés sur une moto, les deux joueurs sont attaqués par des entités menaçantes qui arrivent <b>de l’avant et de l’arrière</b>. Le joueur conducteur <b>esquive les obstacles</b> sur la route en guidant la moto <b>avec un joystick</b>, et <b>contrôle la zone de tir</b> sur laquelle le passager peut interagir.<br /> <br /> Ainsi, le passager peut <b>tirer soit à l’avant, soit à l’arrière</b>. Afin de créer une dynamique coopérative entre les deux joueurs, le conducteur doit garder un œil sur les deux écrans grâce à ses rétroviseurs et indiquer au tireur quand il change la zone de tir.
        </span>),
    whatidid: (
    <span>
      J'ai travaillé sur la conception du projet (branchement et mise en place de panneaux LED) ainsi que sur le <b>sound design</b>, la <b>musique</b> et leur <b>intégration</b> dans le moteur de jeu Unity.
      Le dispositif son est en <b>4.1</b> avec deux enceintes devant et deux enceintes derrière pour donner une sensation de vitesse et donner des indicateurs sonores sur la présence des ennemis.

    </span>
  )},
  { id: 'the-beetles', youtubeUrls: ['https://www.youtube.com/embed/7nbPCCdIap8'], image: BEETLES_1, additionalImages: [BEETLES_2,BEETLES_3], title:'The Beetles',
    description: (
      <span>
        <a href="https://aircooledmax.itch.io/thebeetles" target="_blank" rel="noopener noreferrer"><b>The Beetles</b></a> est un <b>shoot-em-up</b> réalisée en <b>5 jours</b> avec une équipe de dix personnes.
        <br /> <br />
        </span>),
    whatidid: (
    <span>
      J'ai travaillé sur la conception sonore du projet, c'est-à-dire le <b>sound design</b>, la <b>musique</b> ainsi que leur <b>intégration</b> avec FMOD dans le moteur de jeu Unity.

    </span>
  )},
  { id: 'humanophobia', image: HUMANOPHOBIA_3, additionalImages: [HUMANOPHOBIA_1,HUMANOPHOBIA_2], title:'Humanophobia', description: (
    <span>
      <a href="https://yoanb.itch.io/humanophobia" target="_blank" rel="noopener noreferrer"><b>Humanophobia</b></a> est un jeu vidéo de puzzle produit dans le cadre de la GameMaker’s ToolKit GameJam 2023 avec pour thème 'Roles reversed'. Il a été réalisé avec 4 autres personnes rencontrées pour l'occasion.
      </span>),
      whatidid: (
    <span>  
      J'ai travaillé sur la conception du projet ainsi que sur le sound design, la musique et leur intégration dans le moteur de jeu Unity. J'ai aussi travaillé sur les cinématiques, l'histoire, le game design ainsi que la lumière et le post-processing.

    </span>
  )},
  { id: 'erreur-est-humaine', image: ERREUR_1, additionalImages: [ERREUR_2], title:"L'erreur est humaine", description: (
    <span>
      <a href="https://fr.linkedin.com/posts/tristan-bouillon-perron_voici-laffiche-de-mon-film-lerreur-est-activity-7284494456282570752-TnV5" target="_blank" rel="noopener noreferrer"><b>L'erreur est humaine</b></a> est un court métrage d'animation de 8min30.
      </span>),
      whatidid: (
    <span>  
      J'ai effectué l'intégralité du sound design et du mixage du projet sur 4 jours. Le projet sera visionnable prochainement en ligne.

    </span>
  )},
  { id: 'flash-from-the-past', youtubeUrls: ['https://www.youtube.com/embed/SA3jeGLRXBo'], title:'Flash From the Past',

    description: (
      <span>
        <a href="https://goldenrusher.itch.io/flash-from-the-past" target="_blank" rel="noopener noreferrer"><b>Flash from the Past </b></a> est un jeu vidéo narratif d'horreur produit dans le cadre de la Brackeys Game Jam 2023.2 avec pour thème 'Diving Deeper'. Il a été réalisé avec 2 autres personnes rencontrées pour l'occasion.
        </span>),

   whatidid: (
    <span>  
      J'ai travaillé sur la conception du projet ainsi que sur le sound design et son intégration dans le moteur de jeu Unity.

    </span>
  )},

  { id: 'summertime-in-teyvat', youtubeUrls:['https://www.youtube.com/embed/ijRvd6DQhuQ'], image: GENSHIN, category: 'Musique', title: 'Summertime in Teyvat', description: " Mixage d'une musique pop réalisé en une journée." }
];
const ProjectDetails = ({ restoreScrollPosition }) => {
  const { projectId } = useParams();
  const project = portfolioItems.find(item => item.id === projectId);

  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [visibleImageIndex, setVisibleImageIndex] = useState(0);

  if (!project) {
    return <h2>Projet non trouvé</h2>;
  }

  const handleBackClick = () => {
    navigate('/', { replace: false }); // Navigate to the homepage without replacing history

    // Scroll to the `/test` section after navigation
    setTimeout(() => {
      const testSection = document.getElementById('test-section');
      if (testSection) {
        testSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  // Create a combined array of images, videos, and YouTube links
  const images = [
    ...(project.youtubeUrls || []).map(url => ({ type: 'youtube', url })),
    { type: 'image', url: project.image },
    ...(project.additionalImages || []).map(LazyImage => ({
      type: LazyImage.endsWith('.mp4') ? 'video' : 'image',
      url: LazyImage,
    })),
  ];

  // Filter out empty or null media items from the images array
  const filteredImages = images.filter(item => item.url && item.url !== '');

  const handlePrevClick = () => {
    setCurrentImageIndex(prevIndex => (prevIndex === 0 ? filteredImages.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentImageIndex(prevIndex => (prevIndex === filteredImages.length - 1 ? 0 : prevIndex + 1));
  };

  const handleItemClick = (index) => {
    setCurrentImageIndex(index);
    setVisibleImageIndex(index);
  };

  return (
    <div className="project-details">
      <h1 className="centered-title">{project.title}</h1>

      <div className="project-main">
        <div className="project-text">
          <h2>Contexte</h2>
          <p>{project.category}</p>

          <div className="project-description">
            <p>{project.description}</p>
            {project.whatidid && (
              <>
                <h2>Mon travail</h2>
                <p>{project.whatidid}</p>
              </>
            )}
          </div>
        </div>

        <div className="project-image">
          <div className="carousel">
            {/* Bouton précédent */}
            <button className="carousel-button prev" onClick={handlePrevClick}>
              &#9664;
            </button>

            {/* Affichage conditionnel selon le type de média */}
            {filteredImages[currentImageIndex].type === 'youtube' ? (
              <iframe
                className="main-video"
                width="560"
                height="315"
                src={filteredImages[currentImageIndex].url}
                title={project.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : filteredImages[currentImageIndex].type === 'video' ? (
              <video className="main-video" controls>
                <source src={filteredImages[currentImageIndex].url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <LazyImage
                className="main-image"
                src={filteredImages[currentImageIndex].url}
                alt={project.title}
              />
            )}

            {/* Bouton suivant */}
            <button className="carousel-button next" onClick={handleNextClick}>
              &#9654;
            </button>
          </div>

          {/* Images ou vidéos supplémentaires - Render only if filteredImages is not empty */}
          {filteredImages.length > 1 && (
            <div className="project-additional-images">
              {filteredImages.map((item, index) => (
                index !== currentImageIndex && ( // Skip rendering the current media
                  <div
                    key={index}
                    className={`thumbnail ${index === visibleImageIndex ? 'active' : ''}`}
                    onClick={() => handleItemClick(index)}
                  >
                    {item.type === 'video' ? (
                      <video className="additional-video">
                        <source src={item.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : item.type === 'youtube' ? (
                      <LazyImage
                        src={`https://img.youtube.com/vi/${item.url.split('/').pop()}/hqdefault.jpg`}
                        alt={`YouTube Thumbnail ${index + 1}`}
                      />
                    ) : (
                      <LazyImage src={item.url} alt={`Thumbnail ${index + 1}`} />
                    )}
                  </div>
                )
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Bouton Retour */}
      <Link to="/" onClick={restoreScrollPosition}>
        <button className="back-button" onClick={handleBackClick}>
          Retour
        </button>
      </Link>
    </div>
  );
};

export default ProjectDetails;