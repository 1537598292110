import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'

const Contact = () => {

  return (
    <section id='contact'>
      <h1 class="titrecontact">Contact</h1>
      <h2>Envoyez moi un message !</h2>
      <div className="container contact__container" >
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>tanguybruschi.son@gmail.com</h5>
            <a href="mailto:tanguybruschi.son@gmail.com" target="_blank" rel="noreferrer">Envoyer un message</a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className='contact__option-icon'/>
            <h4>Messenger</h4>
            <h5>tanguybruschi</h5>
            <a href="https://m.me/100009197075662" target="_blank"rel="noreferrer">Envoyer un message</a>
          </article>
         {/*} <article className="contact__option">
            <BsWhatsapp className='contact__option-icon'/>
            <h4>Whatsapp</h4>
            <h5>06 51 14 89 **</h5>
            <a href="https://api.whatsapp.com/send?phone=06123456789" target="_blank">Envoyer un message</a>
            
          </article>
  */}
        
        </div>
        {/* 
          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name="name" placeholder="Nom" required />
            <input type="email" name="email" placeholder="Email" required />
            <textarea name="message" rows="7" placeholder="Message" required></textarea>
            <button type='submit' className='btn btn-primary'>Envoyer</button>
          </form>
*/}


      </div>


    </section>
  )
}

export default Contact