import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './project.css';
import MOB from '../../assets/portfoliomain/mob-s3-port.webp';
import ENCOUNTERSMAIN from '../../assets/portfoliomain/encounters-port.webp';
import JUJUTSU from '../../assets/portfoliomain/jujutsu-port.webp';
import CODAMAIN from '../../assets/portfoliomain/coda-port.webp';
import LAFIEVREMAIN from '../../assets/portfoliomain/lafievre-port.webp';
import FRONTIERE from '../../assets/portfoliomain/frontiere_bg-port.png';
import LAMU from '../../assets/portfoliomain/lamu-port.webp';
import HELLUVA from '../../assets/portfoliomain/helluva-port.webp';
import GENSHIN from '../../assets/portfoliomain/genshin_music-port.png';
import VENTORY from '../../assets/portfoliomain/ventory-port.png';
import LITTLE from '../../assets/portfoliomain/biglittlefeud-port.png';
import LUCIFARIN from '../../assets/portfoliomain/lucifarin_thumbnail.jpg';
import SUGARRUSH from '../../assets/portfoliomain/sugar_rush.png';
import THEOUTRUNNER from '../../assets/portfoliomain/theoutrunner.png';
import FLASH from '../../assets/portfoliomain/flashfromthepast.png';
import HUMANOPHOBIA from '../../assets/portfoliomain/Humanophobia_main.png';
import BEETLES from '../../assets/portfoliomain/beetles_image_main.png';
import ERREUR from '../../assets/portfoliomain/erreur_main.png';

import LazyImage from '../../LazyImage';

const Project = () => {
  const [selectedCategory, setSelectedCategory] = useState(() => {
    return localStorage.getItem('selectedCategory') || 'Jeu vidéo';
  });

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    localStorage.setItem('selectedCategory', category);
  };
  const portfolioItems = [
    
    //{ id: 'platinum-end', isEssential: false, image: PLATINUMMAIN, category: 'Doublage', title: 'Platinum End', role1: 'Enregistrement', role2: 'Mixage' },
    { id: 'jujutsu-kaisen', isEssential: false, image: JUJUTSU, category: 'Publicités', title: '', role1: 'Enregistrement', role2: 'Mixage' },
    { id: 'la-fievre', isEssential: false, image: LAFIEVREMAIN, category: 'Fiction', title: 'La Fièvre', role1: 'Enregistrement', role2: 'Montage Son et Mixage' },
    //{ id: 'this-savage-song', isEssential: false, image: SAVAGE, category: 'Publicité', title: 'This Savage Song', role1: 'Enregistrement', role2: 'Mixage' },
    { id: 'mob-psycho-100-s03', isEssential: false, image: MOB, category: 'Doublage', title: 'MOB PSYCHO 100 S03', role1: 'Enregistrement', role2: 'Mixage' },
   // { id: 'missing-the-game', isEssential: false, image: MISSING, category: 'Jeu vidéo', title: 'Missing - The Game', role1: 'Sound Design & Integration', role2: 'Game & Level Design' },
    { id: 'the-beetles', isEssential: true, image: BEETLES, category: 'Jeu vidéo', title: 'The Beetles', role1: 'Sound Design & Integration', role2: 'Musique' },
    { id: 'encounters', isEssential: true, image: ENCOUNTERSMAIN, category: 'Doublage', title: 'Encounters', role1: 'Enregistrement', role2: 'Mixage' },
    { id: 'big-little-feud', isEssential: true, image: LITTLE, category: 'Jeu vidéo', title: 'Big Little Feud', role1: 'Sound Design & Intégration', role2: 'Musique, Game Design' },
    { id: 'la-frontiere-du-monde',isEssential: true, image: FRONTIERE, category: 'Fiction Sonore', title: 'La Frontière Du Monde', role1: 'Enregistrement', role2: 'Montage Son et Mixage' },
    { id: 'ventorys-inn',isEssential: true, image: VENTORY, category: 'Jeu vidéo', title: 'Ventorys Inn', role1: 'Sound Design, Musique', role2: 'Game Design'},
    { id: 'flash-from-the-past',isEssential: false, image: FLASH, category: 'Jeu vidéo', title: 'Flash From the Past', role1: 'Sound Design & Integration'},
    { id: 'humanophobia',isEssential: false, image: HUMANOPHOBIA, category: 'Jeu vidéo', title: 'Humanophobia', role1: 'Sound Design & Integration', role2: 'Musique'},
    { id: 'sugar-rush',isEssential: true, image: SUGARRUSH, category: 'Jeu vidéo', title: 'Sugar Rush', role1: 'Sound Design & Integration', role2: 'Musique'},
    { id: 'outrunner',isEssential: true, image: THEOUTRUNNER, category: 'Jeu vidéo', title: 'The Outrunner', role1: 'Sound Design & Integration', role2: 'Musique'},
    //{ id: 'the-devil-is-a-part-timer-s02',isEssential: false, image: DEVILMAIN, category: 'Doublage', title: 'The Devil is A Part Timer! S02', role1: 'Enregistrement', role2: 'Mixage' },
    { id: 'helluva-boss-vf',isEssential: true, image: HELLUVA, category: 'Fiction', title: 'Helluva Boss VF', role1: 'Mixage' },
    { id: 'urusei-yatsura',isEssential: true, image: LAMU, category: 'Doublage', title: 'Urusei Yatsura', role1: 'Enregistrement', role2: 'Mixage' },
    { id: 'summertime-in-teyvat',isEssential: false, image: GENSHIN, category: 'Musique', title: 'Summertime in Teyvat', role1: 'Mixage' },
    { id: 'erreur-est-humaine',isEssential: true, image: ERREUR, category: 'Fiction', title: "L'erreur est humaine", role1: 'Sound Design', role2: 'Mixage' },
    { id: 'lucifarin-live',isEssential: true, image: LUCIFARIN, category: 'Musique', title: 'Lucifarin Live Session', role1: 'Enregistrement',role2: 'Composition',role3: 'Mixage' },
    { id: 'coda',isEssential: true, image: CODAMAIN, category: 'Magazine musical', title: 'CODA', role1: 'Enregistrement', role2: 'Mixage' }
  ];

  const categoryGradients = {
    Doublage: 'rgba(50, 50, 110, 0.8)',
    Publicité: 'rgba(25, 85, 25, 0.8)',
    Musique: 'rgba(85,85,20, 0.8)',
    'Jeu vidéo': 'rgba(105,35,70,0.8)'
  };

  const categoryMapping = {
    Principaux: item => item.isEssential,
    'TV/Cinema': item => ['Doublage', 'Fiction','Fiction Sonore','Magazine musical', 'Publicités'].includes(item.category),
  };

  const filteredItems =
    selectedCategory === 'All'
      ? portfolioItems
      : selectedCategory in categoryMapping
      ? portfolioItems.filter(categoryMapping[selectedCategory])
      : portfolioItems.filter(item => item.category === selectedCategory);

  const categories = ['Principaux', 'TV/Cinema','Jeu vidéo', 'Musique'];
  return (  
    <div id="test-section" className="section">
      <div className="container portfolioMAIN__container">
        <div className="test">
          <h3> Portfolio</h3>
          <div className="category-buttons">
            {categories.map((category, index) => (
              <button
                key={index}
                className={`category-button ${selectedCategory === category ? 'active' : ''}`}
                onClick={() => handleCategorySelect(category)}
              >
                {category}
              </button>
            ))}
          </div>
          <ul className={`test-content ${selectedCategory !== 'All' ? 'category-grid' : ''}`}>
            {filteredItems.map((item, index) => (
              <li key={index} className={`overlay-image-portfolio ${item.category}`}>
                <Link to={`/project/${item.id}`}>
                  <LazyImage className="image-portfolio" src={item.image} alt='' loading="lazy"/>
                  
                  <div
                    className="hover"
                    style={{
                      backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0.835171568627451) 0%, ${categoryGradients[item.category]} 80%, rgba(0, 0, 0, 0.5) 100%)`
                    }}
                  >
                    <div className="text">
                      <p>{item.title}</p>
                      <br />
                      <h4>{item.category}</h4>
                      <br />
                      <span>{item.role1}</span>
                      <br />
                      <span>{item.role2}</span>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Project;