import React from 'react'
import './services.css'
import wwise101 from '../../assets/certification/wwise101.png'
import dantelv3 from '../../assets/certification/dantelv3.png'
import LazyImage from '../../LazyImage';

const Services = () => {
  return (
    <section id='services'>
      <div class="titreservices">
      <h4 class="h4services">Mes compétences</h4>
      <h2 class="h2services">Secteurs d’activité</h2>
      </div>

      <div className="container services__container">
        <article className="service1">
          <div className="service__content">

          <div className="service__titre">
            <h3>Television</h3>
            <h2>Directs<br></br>Mixage</h2>
          </div>
          <div className="conteneurbandeau">
            <p> Mise en place des matrices d'intercomie et des systèmes HF. Maîtrise des consoles et des outils de postproduction.</p>
            <div className="bandeautv"><LazyImage class="marquee-imagecertif" src={dantelv3} alt=''/>
              </div>
              </div>
          </div>
          
        </article>
        
        {/* End of UI/UX */}
        <article className="service2">
          <div className="service__content">
            <div className="service__titre">
            <h3>Cinema</h3>
            <h2>Doublage, <br></br>Montage son, Mixage</h2>
           </div>
              <p>Enregistrement, édition et mixage de voix pour la fiction ou le documentaire, montage son et enregistrement de sons pour le cinéma. </p>
          </div>
        </article>

        {/* End of UI/UX */}
        <article className="service3">
          <div className="service__content">

          <div className="service__titre">
            <h3>JEUX VIDEO</h3>
            <h2>Sound design,<br></br>Intégration</h2>
          </div>
          <div className="conteneurbandeau">
            <p> Création d'assets et de musiques pour les médias interactifs. Intégration du Sound Design via Wwise ou FMOD.</p>
            <div className="bandeau"><LazyImage class="marquee-imagecertif1" src={wwise101} alt=''/>
              </div>
              </div>
          </div>
          
        </article>

        {/* End of UI/UX */}
        <article className="service4">
          <div className="service__content">
          <div className="service__titre">
            <h3>MUSIQUE</h3>
            <h2>Composition, <br></br>Identite sonore</h2>
          </div>
              <p> Composition de musique à l'image et création d'identité sonore pour les marques.</p>
              
          </div>
        
        </article>
        
        {/* End of UI/UX */}



      </div>
    </section>
  )
}

export default Services