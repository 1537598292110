import React, { useContext, useState, useEffect } from 'react';
import './nowplaying.css';
import { MusicContext } from './MusicContext';

const NowPlayingIndicator = ({ isHovered }) => {
  const { isPlaying, currentTrackIndex, trackNicknames } = useContext(MusicContext);
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [fadeOut, setFadeOut] = useState(false);
  useEffect(() => {
    if (!isPlaying) {
      setMessage("Music Paused");
      setVisible(true);
      setFadeOut(false); // Reset fade-out
  
      const timer = setTimeout(() => {
        setFadeOut(true); // Start fade-out
        setTimeout(() => setVisible(false), 250); // Hide after fade-out duration
      }, 3000);
  
      return () => clearTimeout(timer);
    } else if (isPlaying) {
      setMessage(`Now Playing: ${trackNicknames[currentTrackIndex]}`);
      setVisible(true);
      setFadeOut(false); // Reset fade-out
  
      const timer = setTimeout(() => {
        setFadeOut(true); // Start fade-out
        setTimeout(() => setVisible(false), 250); // Hide after fade-out duration
      }, 3000);
  
      return () => clearTimeout(timer);
    }
  }, [isPlaying, currentTrackIndex, trackNicknames]);
  

  // Determine hover behavior
  const hoverMessage =
    !isPlaying && isHovered && !visible ? "Cliquez pour jouer une musique!" : null;

  return (
    <div className="now-playing-container">
      {visible && (
        <div className={`now-playing-indicator ${fadeOut ? "fade-out" : "fade-in"}`}>
          {message}
        </div>
      )}
      {!visible && hoverMessage && (
        <div className="now-playing-indicator fade-in">
          {hoverMessage}
        </div>
      )}
    </div>
  );
};

export default NowPlayingIndicator;
