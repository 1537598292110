import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import Services from './components/services/Services';
import Project from './components/test/Project';
import NotFound from './pages/notFound/notFound';
import HomePage from './HomePage';
import ProjectDetails from './components/test/ProjectDetails';
import NowPlayingIndicator from './NowPlayingIndicator';

import { MusicProvider } from './MusicContext'; // Import the MusicProvider

const App = () => {
  return (
    <MusicProvider>
      {/* Wrap the entire app with the MusicProvider */}
      <BrowserRouter>
        <Routes>
          {/* Routes principales avec Header et Footer */}
          <Route
            path="/"
            element={
              <>
              <NowPlayingIndicator />
                <Header />
                <HomePage />
                <Footer />
              </>
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/project" element={<Project />} />
          {/* Route spécifique sans Header et Footer */}
          <Route path="/project/:projectId" element={<ProjectDetails />} />
          {/* Route pour page 404 */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </MusicProvider>
  );
};

export default App;