import React from 'react';
import { RiMessengerLine } from 'react-icons/ri';
import { MdOutlineEmail } from 'react-icons/md';
import './header.css';

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      {/* Lien pour envoyer un email */}
      <a
        className="socials"
        href="mailto:tanguybruschi.son@gmail.com?subject=Mail"
        target="_blank"
        rel="noreferrer"
        aria-label="Envoyer un email"
      >
        <MdOutlineEmail />
      </a>

      {/* Lien pour contacter via Messenger */}
      <a
        className="socials"
        href="https://m.me/100009197075662"
        target="_blank"
        rel="noreferrer"
        aria-label="Me contacter sur Messenger"
      >
        <RiMessengerLine />
      </a>

      {/* WhatsApp*/}
      {/* 
      <a
        className="socials"
        href="https://api.whatsapp.com/send?phone=06xxxxxxxx"
        target="_blank"
        rel="noreferrer"
        aria-label="Envoyer un message sur WhatsApp"
      >
        <BsWhatsapp />
      </a> 
      */}
    </div>
  );
};

export default HeaderSocials;
