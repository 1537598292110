import React, { useContext, useState } from 'react';
import './header.css';
import CTA from './CTA';
import GIF from '../../assets/me.gif'; // GIF for playing
import GIF_muted from '../../assets/me-muted.gif'; // GIF for muted
import HeaderSocial from './HeaderSocials';
import { MusicContext } from '../../MusicContext'; // Import the context
import NowPlayingIndicator from '../../NowPlayingIndicator'; // Import the NowPlayingIndicator component

const Header = () => {
  const {
    isMuted,
    togglePlay,
    volume,
    changeVolume,
    nextTrack,
    prevTrack,  
    isPlaying,
  } = useContext(MusicContext);

  const [isHovered, setIsHovered] = useState(false); // Manage hover state

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <header>
      <div className="container header__container">
        <h1>Tanguy Bruschi</h1>
        <h5 className="text-light">Sound designer & Operateur son</h5>
        <HeaderSocial />

        <div className="me">
          <div className="musicplaying" style={{ position: 'relative' }}>
            {/* Volume Slider */}
            {!isMuted && isPlaying && ( // Show only if not muted and music is playing
              <input
                className="volume-slider"
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={(e) => changeVolume(Number(e.target.value))}
              />
            )}

            {/* GIF */}
            <img
              className={`gif ${isPlaying ? 'music-playing' : ''}`} // Add music-playing class when music is playing
              src={isPlaying ? GIF : GIF_muted} // Switch GIF based on isPlaying state
              alt="waveform"
              loading="lazy"
              onClick={togglePlay}
              style={{ cursor: 'pointer' }}
              onMouseEnter={handleMouseEnter} // Trigger hover state
              onMouseLeave={handleMouseLeave} // Reset hover state
            />
            {/* Playlist Controls */}
            <div className={`playlist-controls ${!isPlaying ? 'hidden' : ''}`}>
              <button className="playlist-button" onClick={prevTrack}>
                Previous
              </button>
              <button className="playlist-button" onClick={nextTrack}>
                Next
              </button>
            </div>
          </div>
          <CTA />
          <section id="section-1">
          <a href="#about" id="scroll-btn" aria-label="Scroll to About Section">
  <span className="visually-hidden">Scroll to About Section</span>
</a>
          </section>
        </div>
      </div>

      {/* Pass the isHovered state to NowPlayingIndicator */}
      <NowPlayingIndicator isHovered={isHovered} />
    </header>
  );
};

export default Header;
