  import React, { createContext, useRef, useState } from 'react';

  import track1 from './assets/sound/TheBeetles_gameplay_theme.ogg';
  import track2 from './assets/sound/TheBeetles_menu_theme.ogg';
  import track3 from './assets/sound/SugarRush_gameplay_theme.ogg';
  import track4 from './assets/sound/SugarRush_mainmenu_theme.ogg';
  import track5 from './assets/sound/TheOutrunner_gameplay_theme.ogg';
  import track6 from './assets/sound/TheOutrunner_mainmenu_theme.ogg';
  import track7 from './assets/sound/VentorysInn_gameplay_theme_1.ogg';
  import track8 from './assets/sound/VentorysInn_gameplay_theme_2.ogg';
  import track9 from './assets/sound/VentorysInn_gameplay_theme_3.ogg';
  import track10 from './assets/sound/VentorysInn_gameplay_theme_4.ogg';
  import track11 from "./assets/sound/VentorysInn_gameplay_theme_5.ogg";
  import track12 from './assets/sound/VentorysInn_menu_theme.ogg';
  import track13 from './assets/sound/BigLittleFeud_BossBattle_theme.ogg';
  import track14 from './assets/sound/BigLittleFeud_victory_theme.ogg';
  import track15 from './assets/sound/BigLittleFeud_Village_theme.ogg';
  import track16 from './assets/sound/Humanophobia_gameplay_theme.ogg';
  import track17 from './assets/sound/Humanophobia_mainmenu_theme.ogg';
  
  // Create the MusicContext
  export const MusicContext = createContext();
  
  export const MusicProvider = ({ children }) => {
    const playlist = [
      track1, track2, track3, track4, track5, track6,
      track7, track8, track9, track10, track11, track12,
      track13, track14, track15, track16, track17
    ]; // Define your playlist

    const trackNicknames = [
      "TheBeetles_gameplay_theme", "TheBeetles_menu_theme",
      "SugarRush_gameplay_theme", "SugarRush_mainmenu_theme",
      "TheOutrunner_gameplay_theme", "TheOutrunner_mainmenu_theme",
      "VentorysInn_gameplay_theme_1", "VentorysInn_gameplay_theme_2",
      "VentorysInn_gameplay_theme_3", "VentorysInn_gameplay_theme_4",
      "VentorysInn_gameplay_theme_5", "VentorysInn_menu_theme",
      "BigLittleFeud_BossBattle_theme", "BigLittleFeud_victory_theme",
      "BigLittleFeud_Village_theme", "Humanophobia_gameplay_theme",
      "Humanophobia_mainmenu_theme"
    ];
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [isMuted] = useState(false);
    const [volume, setVolume] = useState(1); // Default volume at max
    const [isPlaying, setIsPlaying] = useState(false);

    // Audio reference initialized to the first track
    const audioRef = useRef(new Audio(playlist[currentTrackIndex]));

    // Play or pause music
    const togglePlay = () => {
      if (audioRef.current) {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          audioRef.current.play();
          audioRef.current.loop = true;
        }
        setIsPlaying(!isPlaying);
      }
    };

    // Change volume
    const changeVolume = (value) => {
      if (audioRef.current) {
        audioRef.current.volume = value;
        setVolume(value); // Update volume state
      }
    };

    // Change the track
    const changeTrack = (index) => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      const newIndex = (index + playlist.length) % playlist.length;
      setCurrentTrackIndex(newIndex);
      audioRef.current = new Audio(playlist[newIndex]);
      audioRef.current.volume = volume;
      if (isPlaying) {
        audioRef.current.play();
        audioRef.current.loop = true;
      }
    };

    // Go to the next track
    const nextTrack = () => {
      changeTrack(currentTrackIndex + 1);
    };

    // Go to the previous track
    const prevTrack = () => {
      changeTrack(currentTrackIndex - 1);
    };

    return (
      <MusicContext.Provider
        value={{
          isMuted,
          togglePlay,
          volume,
          changeVolume,
          nextTrack,
          prevTrack,
          currentTrackIndex,
          playlist,
          trackNicknames,
          isPlaying,
        }}
      >
        {children}
      </MusicContext.Provider>
    );
  };
