import React from 'react'
import './footer.css'

const Footer = () => {
  return (
<footer>
<a href="#top" className="footer__logo">Tanguy Bruschi</a>
<ul className="permalinks">
  <li><a href="#top">Accueil</a></li>
  <li><a href="#about">A propos</a></li>
  <li><a href="#services">Mes compétences</a></li>
  <li><a href="#portfolio">Portfolio</a></li>
  <li><a href="#contact">Contact</a></li>
</ul>
  <div className="footer__copyright">
    <small>&copy; 2022-2025 Tanguy Bruschi - Portfolio audiovisuel.</small>

  </div>
</footer>
  )
}

export default Footer