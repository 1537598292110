import React from 'react'
import './about.css'
import ME from '../../assets/me-about.webp'
import logoBTS from '../../assets/formation/logoBTS.webp'
import logoINA from '../../assets/formation/logoINA.webp'
import logoENJMIN from '../../assets/formation/logoENJMIN.webp'
import fmodLOGO from '../../assets/logiciels/fmodLOGO.webp'
import abletonLOGO from '../../assets/logiciels/abletonLOGO.webp'
import nuendoLOGO from '../../assets/logiciels/nuendoLOGO.webp'
import protoolsLOGO from '../../assets/logiciels/protoolsLOGO.webp'
import unityLOGO from '../../assets/logiciels/unityLOGO.webp'
import wwiseLOGO from '../../assets/logiciels/wwiseLOGO.webp'
import LazyImage from '../../LazyImage';
const About = () => {
  return (
    <section id='about'>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image"> <LazyImage src={ME} alt="Tanguy" /> </div>

            <div className="logiciels">
  <li className="marquee-itemform2">
    <div className="ligne2">Logiciels</div>
  </li>
  <li className="marquee-item3" data-software="Ableton">
    <LazyImage className="marquee-image4" src={abletonLOGO} alt="" />
    <div className="text"></div>
  </li>
  <li className="marquee-item3" data-software="Nuendo">
    <LazyImage className="marquee-image4" src={nuendoLOGO} alt="" />
    <div className="text"></div>
  </li>
  <li className="marquee-item3" data-software="Pro Tools">
    <LazyImage className="marquee-image4" src={protoolsLOGO} alt="" />
    <div className="text"></div>
  </li>
  <li className="marquee-item3" data-software="Wwise">
    <LazyImage className="marquee-image4" src={wwiseLOGO} alt="" />
    <div className="text"></div>
  </li>
  <li className="marquee-item3" data-software="FMOD">
    <LazyImage className="marquee-image4" src={fmodLOGO} alt="" />
    <div className="text"></div>
  </li>
  <li className="marquee-item3" data-software="Unity">
    <LazyImage className="marquee-image4" src={unityLOGO} alt="" />
    <div className="text"></div>
  </li>
</div>
<div className="formations">
  <li className="marquee-itemform">
    <div className="ligne">Formations</div>
  </li>

  <li className="marquee-item4" data-formation="BTS AUDIOVISUEL - OPTION SON (2019-2021)">
    <a href={'//www.facebook.com/bts.audiovisuel.cannes'} target="_blank" rel="noreferrer">
      <LazyImage className="marquee-image3" src={logoBTS} alt="BTS Audiovisuel" />
      <div className="formation_content">
        <p>BTS Audiovisuel <br /> Son  <br /> (2019-2021)</p>
      </div>
    </a>
  </li>

  <li className="marquee-item4" data-formation="LICENCE PRO - INGENIERIE SONORE (2021-2022)">
    <a href={'//www.ina-expert.com/licence-et-diplome-ina/diplome-ina-ingenierie-sonore-specialite-audiovisuel.html'} target="_blank" rel="noreferrer">
      <LazyImage className="marquee-image3" src={logoINA} alt="Licence Pro - Ingénierie Sonore" />
      <div className="formation_content">
        <p>Licence INA<br />Ingénierie sonore  <br /> (2021-2022)</p>
      </div>
    </a>
  </li>

  <li className="marquee-item4" data-formation="MASTER JMIN - CONCEPTION SONORE (2024-2026)">
    <a href={'//enjmin.cnam.fr/formations/master-jeux-video/master-jeux-et-medias-interactifs-numeriques-1026151.kjsp'} target="_blank" rel="noreferrer">
      <LazyImage className="marquee-image3" src={logoENJMIN} alt="Master JMIN - Conception Sonore" />
      <div className="formation_content">
        <p>Master JMIN Conception sonore  <br /> (2024-2026)</p>
      </div>
    </a>
  </li>
</div>

            

    
        </div>



        <div className="about__content">
          <h2>À propos</h2><br></br>
          <p> 
          Bonjour !
          Je suis un étudiant en conception sonore à <sdf class="hover-1"><a href="https://enjmin.cnam.fr/formations/" class="whitea" target="_blank" rel="noreferrer">l'ENJMIN</a></sdf>. <br></br><br></br> 
          Diplômé de l'INA en Ingénierie Sonore <i class="hover-1"><a href="//www.ina-expert.com/licence-et-diplome-ina/diplome-ina-ingenierie-sonore-specialite-audiovisuel.html" class="whitea" target="_blank" rel="noreferrer">spécialité production et postproduction audiovisuelle</a></i>, je suis passionné par le son à l'image.
          <br />J'ai travaillé un an en tant qu'alternant chez <sdf class="hover-1"><a href="//www.time-line.fr/fr/" class="whitea"target="_blank" rel="noreferrer">Time-Line Factory</a></sdf> où j'ai appris l'enregistrement et le mixage de doublage et de publicité. Ensuite, j'ai travaillé deux ans en tant qu'opérateur son et sound designer.<br></br>
          <br />
            J'aime l'adrénaline des deadlines, le travail de détail en studio et les projets ambitieux en équipe.<br></br> J'aime créer et expérimenter. Je suis un touche-à-tout qui adore apprendre de nouvelles compétences.<br></br>
            <br />
            Je maîtrise les aspects du direct (matrices, AOIP, HF) ainsi que ceux de la postproduction (montage son, sound design, mixage). Je connais bien Unity et son système audio et je me forme sur Unreal et Wwise.
            <br /><br />
          Pianiste, guitariste et chanteur, j'ai joué dans plusieurs groupes et compose des morceaux dans des styles variés.
          <br /><br />
          En dessous, vous pourrez trouver une gamme de projets sur lesquels j'ai travaillé.
          </p>


        </div>
        
      </div>
        
    
    </section>
  )
}

export default About