import React from "react";
import ReacDOM from 'react-dom'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import HomePage from './HomePage'

import {BrowserRouter, Routes, Route} from 'react-router-dom'

const rootElement = document.getElementById("root");
ReactDOM.render(
  <BrowserRouter>
   <Routes>
   <Route exact path="/" component={<HomePage/>} />
  </Routes>
  </BrowserRouter>,
  rootElement
);
  
ReacDOM.render(<App/>, document.querySelector("#root"));