import React from 'react'
import About from './components/about/About'
import Contact from './components/contact/Contact'
import Services from './components/services/Services'
import Project from './components/test/Project'

const HomePage = () => {

  return (
    <>
    <About />
    <Services/>
    <Project/>
    <Contact/>
    </>
  )
}

export default HomePage